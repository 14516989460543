/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import MapTest from "../googlemaps/MapTest";
import { CAPITALIZE, GET_TIME_OF_DAY } from "../../utilities/helper";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  LocumBookingResponse,
  SetAvailabilitySliceBits,
} from "../../store/modules/bookingSlice";
import AntDesignButton from "../others/Button/AntDesignButton";
import { Card, Col, Row } from "antd";
import { DAYS_ABBR, ROLES } from "../../utilities/enumerations";
import { Icon } from "@iconify/react";
import { CreateChatChannel } from "../../store/modules/chatSlice";

const BookingRequestCard = ({
  index,
  practice_user_name,
  practice_id,
  practice_lat,
  practice_lng,
  booking_id,
  practice_time_range,
  requested_dates,
  practice_full_Name,
}) => {
  const account = useSelector((state) => state.account);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const availabilites = useSelector((state) => state.bookings);

  // * Check if booking confirmed and show success screen
  useEffect(() => {
    if (availabilites.isRequestStatusUpdated) {
      dispatch(SetAvailabilitySliceBits({ bitToSet: "isRequestStatusUpdated", value: false }));
      navigate(
        `/${account.role}/booking_confirmed/${booking_id}`,
        {
          state: {
            booking_id: booking_id,
          },
        },
        true
      );
    }
  }, [availabilites.isRequestStatusUpdated]);

  //* Get parsed dates adn time
  const getParsedDates = (jsonDates, timeRange, name) => {
    var requested_dates = JSON.parse(jsonDates);
    var dates =
      requested_dates &&
      requested_dates.length > 0 &&
      requested_dates.map((item, index) => (
        <p className="d-block m-0" key={index}>
          {
            // index +
            // 1 +
            // ": " +
            dayjs(item).format("DD/MM/YYYY") +
            " " +
            dayjs(item).format("dddd") +
            " " +
            "" +
            GET_TIME_OF_DAY(DAYS_ABBR[dayjs(item).get("day")], timeRange) +
            " @ " +
            name
          }
        </p>
      ));
    return dates;
  };

  //* Decline booking
  const handleDeclineBooking = (booking_id) => {
    navigate(
      `/${account.role}/decline_booking/${booking_id}`,
      {
        state: {
          booking_id: booking_id,
        },
      },
      true
    );
  };

  //* Accept booking
  const handleAcceptBooking = (booking_id) => {
    dispatch(
      LocumBookingResponse({
        data: {
          booking_id: booking_id,
          status: true,
        },
        user_token: account.user_token,
      })
    );
  };

  const handleMessageNow = (id) => {
    dispatch(
      CreateChatChannel({
        userId: id,
        user_token: account.user_token,
      })
    );
  };

  return (
    <Row key={index}>
      <Col span={24}>
        <Card hoverable className="booking_request_card" key={index}>
          <Row gutter={[20, 10]}>
            <Col xs={24} sm={24} md={24} lg={12} className="request_card_map">
              <MapTest
                lat={practice_lat}
                lng={practice_lng}
                zoom={15}
                showGetDirection={true}
                fullscreenControl={false}
                mapTypeControl={false}
                skeletonBorderRadius={true}
              ></MapTest>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <h5>
                ({CAPITALIZE(practice_user_name)}) is requesting booking for the
                following dates
              </h5>
              <div className="requested_dates">
                <h5>Requested dates</h5>
                <div>
                  {getParsedDates(
                    requested_dates,
                    practice_time_range,
                    practice_full_Name
                  )}
                </div>
              </div>
              <p>
                Please review the practice profile (
                <NavLink to={`/${account.role}/user_profile/${practice_id}`} state={{ user_id: practice_id }}>{CAPITALIZE(practice_user_name)}</NavLink>
                ) and confirm booking if your happy to proceed.
              </p>
              <div className="col-9 icon-with-detail">
                <div className="">
                  <Icon
                    icon="ic:round-message"
                    color="#5ae8a0"
                    width="22"
                    height="22"
                  />
                </div>
                <div className="">
                  <NavLink
                    onClick={() => handleMessageNow(practice_id)}
                    to={`/${ROLES[account.role]}/chat`}
                    state={{ user_id: practice_id }}
                  >
                    Send a message
                  </NavLink>
                </div>
              </div>
              <Row gutter={[10, 0]}>
                <Col span={12}>
                  <AntDesignButton
                    block={true}
                    btn_label="Confirm Booking"
                    handleClick={() => handleAcceptBooking(booking_id)}
                  />
                </Col>
                <Col span={12}>
                  <AntDesignButton
                    danger={true}
                    block={true}
                    btn_label="Decline Booking"
                    handleClick={() => handleDeclineBooking(booking_id)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default BookingRequestCard;
