import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox } from "@nextui-org/react";
import {
  DeleteAccount,
  UpdateProfilePassword,
} from "../../store/modules/accountSlice";
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { confirmAlert } from "react-confirm-alert";
import MuiPasswordInput from "../../components/others/MuiPasswordInput";
import {
  ValidateDeleteaccount,
  ValidateChangePassword,
} from "../../utilities/validator";
import Label from "../../components/others/Label/Label";
import { ChangeNotificationSettings, SetSettingsSliceBits } from "../../store/modules/settingSlice";

const Settings = () => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const settings = useSelector((state) => state.settings);
  const [data, setData] = useState({
    password: "",
  });
  const [passData, setPassData] = useState({
    old_password: "",
    new_password: "",
    c_new_password: "",
  });

  const [formError, setFormError] = useState({});
  const [changePassErr, setChangePassErr] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingPassChange, setIsSubmittingPassChange] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);

  const handleCheckboxSelect = (val) => {
    dispatch(
      ChangeNotificationSettings({
        user_token: account.user_token,
        notification_type: val.type,
        value: val.value,
      })
    );
    setEmailChecked(val.value);
  };

  // #region delete acc functions
  useEffect(() => {
    if (account.profile_data && account.profile_data.basic_info) {
      account.profile_data.basic_info.email_notifications == "1"
        ? setEmailChecked(true)
        : setEmailChecked(false);
    }
  }, [account.profile_data]);

  useEffect(() => {
    if (settings.is_update_success) {
      dispatch(
        SetSettingsSliceBits({
          bitToSet: "is_update_success",
          value: false,
        })
      );
    }
  }, [dispatch, settings.is_update_success])


  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmitting) {
      submitForm(true);
    }
  }, [formError]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(ValidateDeleteaccount(data));
    setIsSubmitting(true);
  };

  const submitForm = () => {
    try {
      confirmAlert({
        //  title: "Confirm to submit",
        message: "Are you sure to delete your account?",
        buttons: [
          {
            label: "Yes",
            onClick: () =>
              dispatch(
                DeleteAccount({ ...data, ["user_token"]: account.user_token })
              ),
          },
          {
            label: "No",
          },
        ],
      });
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  };
  // #endregion

  // #region change pass functions
  useEffect(() => {
    if (Object.keys(changePassErr).length === 0 && isSubmittingPassChange) {
      submitFormChangePass(true);
    }
  }, [changePassErr]);

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    setPassData({ ...passData, [name]: value });
    setChangePassErr({ ...formError, [name]: "" });
  };

  const handleSubmitPassword = (e) => {
    e.preventDefault();
    setChangePassErr(ValidateChangePassword(passData, true));
    setIsSubmittingPassChange(true);
  };

  const submitFormChangePass = () => {
    try {
      dispatch(
        UpdateProfilePassword({
          ...passData,
          ["user_token"]: account.user_token,
        })
      );
      setIsSubmittingPassChange(false);
    } catch (error) {
      console.log(error);
    }
  };
  // #endregion

  return (
    <div className="settings" id="settings">
      <div className="row">
        <div className="col-lg-12 m-auto">
          <h5 className="text-black">Settings</h5>
          <hr></hr>
          {/* delete acc  */}
          {/* <form onSubmit={handleSubmit}>
            <div className="form-group mt-2">
              <MuiInput
                label="Delete Account"
                bold={false}
                type="text"
                className="form-control"
                id="password"
                name="password"
                inputError={formError.password}
                inputValue={data.password}
                handleChange={handleChange}
                placeholder="Enter password to confirm"
              ></MuiInput>
            </div>
            <div className="form-group mt-4 mb-3">
              <Button
                type="submit"
                sx={{ width: 200, height: 45 }}
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </div>
          </form> */}

          {/* <hr></hr> */}
          {/* Change password */}
          <form onSubmit={handleSubmitPassword}>
            <div className="form-group mt-2">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <MuiPasswordInput
                    label="Old Password"
                    id="useroldpass"
                    name="old_password"
                    placeholder="Enter your password"
                    inputValue={passData.old_password}
                    inputError={changePassErr.old_password}
                    handleChange={handlePasswordChange}
                    autocomplete={"off"}
                  ></MuiPasswordInput>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <MuiPasswordInput
                    label="New Password"
                    id="usernewpass"
                    name="new_password"
                    placeholder="Enter your password"
                    inputValue={passData.new_password}
                    inputError={changePassErr.new_password}
                    handleChange={handlePasswordChange}
                    autocomplete={"off"}
                  ></MuiPasswordInput>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <MuiPasswordInput
                    label="Confirm New Password"
                    id="usernewpass_c"
                    name="c_new_password"
                    placeholder="Confirm your new password"
                    inputValue={passData.c_new_password}
                    inputError={changePassErr.c_new_password}
                    handleChange={handlePasswordChange}
                    autocomplete={"off"}
                  ></MuiPasswordInput>
                </div>
              </div>
            </div>
            <div className="form-group mt-4">
              <Button
                type="submit"
                sx={{
                  width: 200,
                  height: 45,
                  backgroundColor: "#4493c5",
                  ":hover": { backgroundColor: "#4493c5" },
                }}
                variant="contained"
                color="primary"
                startIcon={<SaveAltIcon />}
              >
                Save
              </Button>
            </div>
          </form>
          <hr></hr>
          <div className="form-group mt-4 mb-5">
            <Label label="Notifications"></Label>
            <div className="row m-0 mt-2">
              <Checkbox
                isSelected={emailChecked}
                size="md"
                name="email"
                onChange={(event) =>
                  handleCheckboxSelect({
                    type: "email_notifications",
                    value: event,
                  })
                }
              >
                Email Notifications
              </Checkbox>
            </div>
            <div className="row m-0 mt-2">
              <Checkbox
                isDisabled={true}
                // isSelected={emailChecked}
                size="md"
                name="email"
                onChange={(event) =>
                  handleCheckboxSelect({
                    type: "email_notifications",
                    value: event,
                  })
                }
              >
                Sms Notifications
              </Checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
