import React from "react";
import Shirts from "../../assets/images/shirts.png";

const Secure = (props) => {
  return (
    <div className="col-lg-4 mt-3 mt-lg-0">
      <div className="card">
        <div className="card-body">
          <img src={Shirts} alt="Img"></img>
          <h4 className="card-title mt-4">Card title</h4>
          <p className="card-text">
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </p>
          <a href="#" className="text-decoration-none">
            Go somewhere
          </a>
        </div>
      </div>
    </div>
  );
};

export default Secure;
