import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ValidateUpdateProfileLocum } from "../../../utilities/validator";
import { UpdateProfile } from "../../../store/modules/accountSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { APPOINTMENT_TIME, CHARGE_RATE, EXCLUDED_COMPANIES, LANGUAGE_LIST, PREVIOUS_CONPANIES, SOFTWARE_USED } from "../../../utilities/enumerations";
import MuiDropdown from "../../../components/others/MuiDropdown";
import MuiInput from "../../../components/others/MuiInput";
import ButtonCheckBox from "../../../components/others/ButtonCheckbox/ButtonCheckBox";
import { SetTaskMapModal } from "../../../store/modules/modalSlice";
import MapModal from "../../../components/modals/MapModal";
import { SetAlert } from "../../../store/modules/alertSlice";
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import TimeRange from "../../../components/others/TimeRange/TimeRange";
import Label from "../../../components/others/Label/Label";
import IsProcessing from "../../../components/others/IsProcessing/IsProcessing";
import "./style.scss";
import { toast } from "react-hot-toast";


const LocumProfileEdit = ({ profileName, user_id = null }) => {
  const dispatch = useDispatch();
  const { profile_data, user_token, a_is_processing } = useSelector(
    (state) => state.account
  );
  const modal = useSelector((state) => state.modal);
  const [formError, setFormError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [getLocationError] = useState({
    code: "",
    message: "",
  });
  const [timeRange, setTimeRange] = useState({
    mon: {
      start: null,
      end: null,
    },
    tue: {
      start: null,
      end: null,
    },
    wed: {
      start: null,
      end: null,
    },
    thu: {
      start: null,
      end: null,
    },
    fri: {
      start: null,
      end: null,
    },
    sat: {
      start: null,
      end: null,
    },
    sun: {
      start: null,
      end: null,
    },
  });
  const [data, setData] = useState({
    user_id: 0,
    user_name: "",
    email: "",
    full_name: "",
    prefix: "",
    mobile_number: "",
    ahpra_number: "",
    sector: "",
    provider_number: "",
    charge_rate: "",
    charge_rate_sat: "",
    charge_rate_sun: "",
    min_shift_hours: "",
    location_subrub: "",
    location: "",
    lat: "",
    lng: "",
    distance_range: "",
    office_address: "",
    available_time: "",
    excluded_company: "",
    vaccination_status: "",
    experience_years: "",
    appointment_time: "",
    language_1: "",
    language_2: "",
    language_3: "",
    profile: "",
    other_softwares: "",
    softwares: [],
    prev_companies: [],
    excluded_companies: [],
    time_range: {},
  });


  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      time_range: timeRange
    }))
  }, [timeRange]);

  useEffect(() => {
    if (user_id) {
      setData((prevData) => ({
        ...prevData,
        user_id: user_id,
      }));
    }
  }, [user_id]);

  // Set data on page load and profile_data change
  useEffect(() => {
    if (
      profile_data &&
      profile_data.basic_info &&
      profile_data.detailed_info
    ) {
      setData((prevData) => ({
        ...prevData,
        user_name: profile_data.basic_info.user_name,
        email: profile_data.basic_info.email,
        full_name: profile_data.basic_info.full_name,
        prefix: profile_data.basic_info.prefix,
        mobile_number: profile_data.basic_info.mobile_number,


        ahpra_number: profile_data.detailed_info.ahpra_number,
        sector: profile_data.detailed_info.sector,
        provider_number: profile_data.detailed_info.provider_number,
        charge_rate: profile_data.detailed_info.charge_rate,
        charge_rate_sat: profile_data.detailed_info.charge_rate_sat,
        charge_rate_sun: profile_data.detailed_info.charge_rate_sun,
        min_shift_hours: profile_data.detailed_info.min_shift_hours,
        location_subrub: profile_data.detailed_info.location_subrub,
        location: profile_data.detailed_info.location,
        lat: profile_data.detailed_info.lat,
        lng: profile_data.detailed_info.lng,
        distance_range: profile_data.detailed_info.distance_range,
        office_address: profile_data.detailed_info.office_address,
        available_time: profile_data.detailed_info.available_time,
        excluded_company: profile_data.detailed_info.excluded_company,
        vaccination_status: profile_data.detailed_info.vaccination_status,
        experience_years: profile_data.detailed_info.experience_years,
        appointment_time: profile_data.detailed_info.appointment_time,
        language_1: profile_data.detailed_info.language_1,
        language_2: profile_data.detailed_info.language_2,
        language_3: profile_data.detailed_info.language_3,
        profile: profile_data.detailed_info.profile,
        other_softwares: profile_data.detailed_info.other_softwares,
        softwares: profile_data.detailed_info.softwares || [],
        prev_companies: profile_data.detailed_info.prev_companies || [],
        excluded_companies: profile_data.detailed_info.excluded_companies || [],
        time_range: profile_data.detailed_info.time_range,

      }));

      setTimeRange((prevData) => ({
        ...prevData,
        ...profile_data.detailed_info.time_range,
      }));
    }
  }, [profile_data]);

  //listen for location data changes
  useEffect(() => {
    if (modal.distanceRange && modal.distanceRange !== "") {
      setData((prevData) => ({
        ...prevData,
        lat: modal.userLat,
        lng: modal.userLng,
        distance_range: modal.distanceRange,
        location: modal.location,
      }));
      setFormError((prevData) => ({
        ...prevData, distance_range: ""
      }));
    }
  }, [modal.distanceRange, modal.location, modal.userLat, modal.userLng]);

  const submitForm = useCallback(() => {
    try {
      dispatch(UpdateProfile({ ...data, user_token: user_token }));
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  }, [data, dispatch, user_token])

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmitting) {
      submitForm(true);
    }
  }, [formError, isSubmitting, submitForm]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(ValidateUpdateProfileLocum(data));
    setIsSubmitting(true);
  };

  // Software Checkbox Change
  const handleSoftwareCheckboxChange = (event) => {
    const array = data.softwares ? [...data.softwares] : [];
    if (event.target.checked) {
      const index = findIndex(array, event.target.value);
      if (index >= 0) {
        array.splice(index, 1);
      } else {
        array.push(event.target.value);
      }
    } else {
      const index = findIndex(array, event.target.value);
      if (index >= 0) {
        array.splice(index, 1);
      }
    }
    setData({ ...data, [event.target.name]: array });
  };

  // Prev Companies Checkbox Change
  const handlePrevCompaniesCheckboxChange = (event) => {
    const array = data.prev_companies ? [...data.prev_companies] : [];
    if (event.target.checked) {
      const index = findIndex(array, event.target.value);
      if (index >= 0) {
        array.splice(index, 1);
      } else {
        array.push(event.target.value);
      }
    } else {
      const index = findIndex(array, event.target.value);
      if (index >= 0) {
        array.splice(index, 1);
      }
    }
    setData({ ...data, [event.target.name]: array });
  };

  // Excluded Companies Checkbox Change
  const handleExcludedCompaniesCheckboxChange = (event) => {
    const array = data.excluded_companies ? [...data.excluded_companies] : [];
    if (event.target.checked) {
      const index = findIndex(array, event.target.value);
      if (index >= 0) {
        array.splice(index, 1);
      } else {
        array.push(event.target.value);
      }
    } else {
      const index = findIndex(array, event.target.value);
      if (index >= 0) {
        array.splice(index, 1);
      }
    }
    setData({ ...data, [event.target.name]: array });
  };

  const findIndex = (array, name) => {
    return array.findIndex((item) => item === name);
  };

  const handleClickOpenMapModal = () => {
    var info = {
      // lat: coords.latitude,
      // lng: coords.longitude,
      // lat: data.lat,
      // lng: data.lng,
      lat: 	52.45389938,
      lng: -1.748029947,
      showGetDirection: false,
      showRadiusOptions: true,
      draggable: true,
      clickable: true,
    };
    dispatch(
      SetTaskMapModal({
        mapModalOpen: true,
        location_data: info,
      })
    );
    dispatch(
      SetAlert({
        alertOpen: true,
        alertMessage:
          "You can drag marker or click on map to make your position more precise.Enter distance to mark your distance range you can travel.",
        alertType: "info",
        alertTitle: "Maps",
      })
    );
  };

  // Time range handle change
  const handleTimeRangeChange = useCallback((timeRangeArr, dayName) => {
    setTimeRange((currVal) => {
      return {
        ...currVal,
        [dayName]: {
          ...currVal.dayName,
          start: timeRangeArr[0],
          end: timeRangeArr[1],
        },
      };
    });
  }, [])


  return (
    <div>
      <MapModal showGetDirection={false}></MapModal>
      <div className="locum_acc_details">
        {!a_is_processing ? (
          <div className="row">
            <div className="col-lg-12 m-auto">
              <h3 className="text text-black">
                {profileName && profileName !== ""
                  ? profileName + " Profile"
                  : profile_data.basic_info.user_name + " Profile page"}
              </h3>
              <hr></hr>
              <form onSubmit={handleSubmit}>
                {/* Time range */}
                <div className="row mt-3">
                  <div className="col">
                    <Label
                      label={"Time available"}
                      isRequired={true}
                      bold={true}
                    ></Label>
                    <TimeRange
                      handleTimeRange={handleTimeRangeChange}
                      timeRange={timeRange}
                    ></TimeRange>
                    <small className="input_errors">{formError.time_range}</small>
                  </div>
                </div>

                {/* Appointment time */}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <MuiDropdown
                      isRequired={true}
                      bold={true}
                      label={"Appointment Time"}
                      id={"appointment_time"}
                      name={"appointment_time"}
                      inputValue={data.appointment_time}
                      inputError={formError.appointment_time}
                      handleChange={handleChange}
                      options={APPOINTMENT_TIME}
                    ></MuiDropdown>
                  </div>
                </div>

                {/* Charge rate */}
                <div className="row mt-3">
                  <Label
                    label={"Charge Rate"}
                    isRequired={true}
                    bold={true}
                  ></Label>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <MuiDropdown
                      label={"Weekday"}
                      id={"charge_rate"}
                      name={"charge_rate"}
                      inputValue={data.charge_rate}
                      inputError={formError.charge_rate}
                      handleChange={handleChange}
                      options={CHARGE_RATE}
                    ></MuiDropdown>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <MuiDropdown
                      label={"Saturday"}
                      id={"charge_rate_sat"}
                      name={"charge_rate_sat"}
                      inputValue={data.charge_rate_sat}
                      inputError={formError.charge_rate_sat}
                      handleChange={handleChange}
                      options={CHARGE_RATE}
                    ></MuiDropdown>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <MuiDropdown
                      label={"Sunday"}
                      id={"charge_rate_sun"}
                      name={"charge_rate_sun"}
                      inputValue={data.charge_rate_sun}
                      inputError={formError.charge_rate_sun}
                      handleChange={handleChange}
                      options={CHARGE_RATE}
                    ></MuiDropdown>
                  </div>
                </div>

                {/* Languages */}
                <div className="row mt-3">
                  <Label
                    label={"Other language able to communicate in"}
                    bold={true}
                  ></Label>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <MuiDropdown
                      label={"Language 1"}
                      id={"language_1"}
                      name={"language_1"}
                      inputValue={data.language_1}
                      inputError={formError.language_1}
                      handleChange={handleChange}
                      options={LANGUAGE_LIST}
                    ></MuiDropdown>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <MuiDropdown
                      label={"Language 2"}
                      id={"language_2"}
                      name={"language_2"}
                      inputValue={data.language_2}
                      inputError={formError.language_2}
                      handleChange={handleChange}
                      options={LANGUAGE_LIST}
                    ></MuiDropdown>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <MuiDropdown
                      label={"Language 3"}
                      id={"language_3"}
                      name={"language_3"}
                      inputValue={data.language_3}
                      inputError={formError.language_3}
                      handleChange={handleChange}
                      options={LANGUAGE_LIST}
                    ></MuiDropdown>
                  </div>
                </div>

                {/* Experience years */}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <MuiInput
                      bold={true}
                      label="Experience (Years)"
                      id="experience_years"
                      placeholder="Enter your ahpra number"
                      inputError={formError.experience_years}
                      inputValue={data.experience_years}
                      type="number"
                      min={0}
                      max={100}
                      handleChange={handleChange}
                      name="experience_years"
                    ></MuiInput>
                  </div>
                </div>

                {/* Previous Companies */}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <Label label={"Previous Companies"} bold={true}></Label>
                    <div className="mt-1">
                      {PREVIOUS_CONPANIES.map((ele, index) => {
                        return (
                          <ButtonCheckBox
                            key={index}
                            name={"prev_companies"}
                            value={ele.name}
                            id={ele.id}
                            label={ele.label}
                            handleCheckboxChange={
                              handlePrevCompaniesCheckboxChange
                            }
                            isChecked={
                              data.prev_companies &&
                                data.prev_companies.length > 0 &&
                                data.prev_companies.includes(ele.name)
                                ? true
                                : false
                            }
                          />
                        );
                      })}
                      
                      {formError.prev_companies && <small className="input_errors">{formError.prev_companies}</small>}
                    </div>
                  </div>
                </div>

                {/* Softwares Used */}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <Label label={"Softwares Used"} bold={true}></Label>
                    <div className="mt-1">
                      {SOFTWARE_USED.map((ele, index) => {
                        return (
                          <ButtonCheckBox
                            key={index}
                            name={"softwares"}
                            value={ele.name}
                            id={ele.id}
                            label={ele.label}
                            handleCheckboxChange={handleSoftwareCheckboxChange}
                            isChecked={
                              data.softwares &&
                                data.softwares.length > 0 &&
                                data.softwares.includes(ele.name)
                                ? true
                                : false
                            }
                          />
                        );
                      })}
                                          {formError.softwares && <small className="input_errors">{formError.softwares}</small>}

                    </div>
                  </div>
                </div>

                {/* Other software used */}
                {data.softwares &&
                  data.softwares.length > 0 &&
                  data.softwares.includes("others") && (
                    <div className="row mt-3">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <MuiInput
                          bold={true}
                          label="Enter other software"
                          id="other_softwares"
                          placeholder="Enter other software name"
                          inputValue={data.other_softwares}
                          type="text"
                          handleChange={handleChange}
                          name="other_softwares"
                        ></MuiInput>
                      </div>
                    </div>
                  )}

                {/* Exclude companies */}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <Label
                      label={
                        "COMPANIES TO EXCLUDE (these companies won't see your availability)"
                      }
                      bold={true}
                    ></Label>
                    <div className="mt-1">
                      {EXCLUDED_COMPANIES.map((ele, index) => {
                        return (
                          <ButtonCheckBox
                            key={index}
                            name={"excluded_companies"}
                            value={ele.name}
                            id={ele.id}
                            label={ele.label}
                            handleCheckboxChange={
                              handleExcludedCompaniesCheckboxChange
                            }
                            isChecked={
                              data.excluded_companies &&
                                data.excluded_companies.length > 0 &&
                                data.excluded_companies.includes(ele.name)
                                ? true
                                : false
                            }
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>

                {/* Profile description */}
                <div className="row mt-3">
                  <div className="col">
                    <div className="form-group mt-2">
                      <label className="pass_label">
                        <strong>Profile</strong>
                      </label>
                      <textarea
                        className="form-control custom-textarea"
                        id="profile"
                        rows="3"
                        name="profile"
                        onChange={handleChange}
                        placeholder="Tell us about yourself"
                        value={data.profile}
                      ></textarea>
                      <small className="input_errors">
                        {formError.profile}
                      </small>
                    </div>
                  </div>
                </div>

                {/* Location and distance range */}
                <div className="row mt-3">
                  <div className="col">
                    <Label
                      label={
                        "Your location and the distance range willing to travel within"
                      }
                      bold={true}
                      isRequired={true}
                    ></Label>
                    <button
                      disabled={
                        getLocationError && getLocationError.code !== ""
                          ? true
                          : false
                      }
                      onClick={handleClickOpenMapModal}
                      style={{
                        backgroundColor: "#4493c5",
                        borderColor: "#4493c5",
                      }}
                      type="button"
                      className="btn btn-primary d-block mt-2"
                    >
                      <small> Choose on Map</small>
                    </button>
                    <small className="input_errors">
                      {formError.distance_range}
                    </small>
                    {getLocationError && getLocationError.code !== "" && (
                      <>
                        <div className="alert alert-danger mt-3" role="alert">
                          <FontAwesomeIcon
                            className=""
                            icon="fa-solid fa-circle-info"
                          />
                          &nbsp;
                          {getLocationError.code === 1
                            ? getLocationError.message +
                            ". You must allow location access in your browser"
                            : getLocationError.message}
                        </div>
                      </>
                    )}
                    {data.distance_range && data.distance_range !== "" && (
                      <>
                        <div className="row">
                          <div className="col">
                            <MuiInput
                              label="Latitude"
                              id="lat"
                              // placeholder="Enter full name"
                              inputError={formError.lat}
                              inputValue={data.lat}
                              type="text"
                              disabled={true}
                              handleChange={handleChange}
                              name="lat"
                            ></MuiInput>
                          </div>
                          <div className="col">
                            <MuiInput
                              label="Longitude"
                              id="lng"
                              // placeholder="Enter full name"
                              inputError={formError.lng}
                              inputValue={data.lng}
                              type="text"
                              disabled={true}
                              handleChange={handleChange}
                              name="lng"
                            ></MuiInput>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <MuiInput
                              label="Location"
                              id="location"
                              // placeholder="Enter full name"
                              inputError={formError.location}
                              inputValue={data.location}
                              type="text"
                              disabled={true}
                              handleChange={handleChange}
                              name="location"
                            ></MuiInput>
                          </div>
                          <div className="col">
                            <MuiInput
                              label="Distance range"
                              id="distance_range"
                              // placeholder="Enter full name"
                              inputError={formError.lat}
                              inputValue={data.distance_range}
                              type="text"
                              disabled={true}
                              handleChange={handleChange}
                              name="distance_range"
                            ></MuiInput>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {/* Submit Button */}
                <div className="form-group mt-4 mb-2">
                  <Button
                    type="submit"
                    sx={{
                      width: 200,
                      height: 45,
                      backgroundColor: "#4493c5",
                      ":hover": { backgroundColor: "#4493c5" },
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<SaveAltIcon />}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <IsProcessing></IsProcessing>
        )}
      </div>
    </div>
  );
};

export default LocumProfileEdit;
